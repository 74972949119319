'use client';
import { useRouter, useSearchParams } from 'next/navigation';
import { memo, useCallback } from 'react';
import { NameConstants } from '~/utils/constants';
import { routes } from '~/utils/routes';
import { Button } from '@app/_components/button';
import { Link } from '@app/_components/link';
import { css } from '@styled-system/css';
import { HStack, Image } from '@styled-system/jsx';
import logo from './assets/logo.svg';

const HEADER_PC_HEIGHT = 72;
const HEADER_SP_HEIGHT = 50;

type VisibilityConfig = Partial<{
  // Show login button
  loginButton: boolean;

  // Show register button
  registerButton: boolean;
}>;

type Props = {
  // 表示状態
  visibilityConfigs?: VisibilityConfig;
};

const _AuthHeader = ({ visibilityConfigs }: Props) => {
  const searchParams = useSearchParams();
  const from = decodeURIComponent(searchParams?.get('from') ?? '');
  const router = useRouter();
  const handleOnClickRegister = useCallback(() => {
    router.push(routes.register.url(undefined, from));
  }, [from, router]);
  const handleOnClickLogin = useCallback(() => {
    router.push(routes.login.url(from));
  }, [from, router]);

  return (
    <header
      className={css({
        position: 'relative',
        bg: 'white',
        width: '100%',
        pl: {
          base: '16px',
          lg: '40px',
        },
        pr: {
          base: '16px',
          lg: '30px',
        },
        py: {
          base: '8px',
          lg: '16px',
        },
        height: {
          base: `${HEADER_SP_HEIGHT}px`,
          md: `${HEADER_PC_HEIGHT}px`,
        },
        borderBottom: '0.5px solid #D7D8DA',
        textAlign: 'left',
      })}
    >
      <HStack
        justifyContent="space-between"
        maxWidth={{ base: '100%', lg: '1220px' }}
        margin="auto"
      >
        <Link href="/">
          <Image
            src={logo}
            loading="lazy"
            alt={NameConstants.SITE_NAME_WITH_EN}
            width={{
              base: '135px',
              lg: '145px',
            }}
            marginBottom="5px"
            cursor="pointer"
            _hover={{
              opacity: 0.8,
            }}
          />
        </Link>
        {visibilityConfigs?.registerButton && (
          <Button
            onClick={handleOnClickRegister}
            size="medium"
            type="whiteOverBlack"
            lineHeight={1}
          >
            無料登録
          </Button>
        )}
        {visibilityConfigs?.loginButton && (
          <Button
            onClick={handleOnClickLogin}
            size="medium"
            type="outline"
            lineHeight={1}
          >
            ログイン
          </Button>
        )}
      </HStack>
    </header>
  );
};
export const AuthHeader = memo(_AuthHeader);
