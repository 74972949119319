'use client';

import { useSelectedLayoutSegments } from 'next/navigation';
import { AuthHeader } from '@app/auth/_components/auth-header';

export const HeaderContainer = () => {
  const segments = useSelectedLayoutSegments();
  const isLoginLandingPage = segments?.includes('login') ?? false;
  const isRegisterLandingPage = segments?.includes('register') ?? false;

  return (
    <AuthHeader
      visibilityConfigs={{
        loginButton: isRegisterLandingPage,
        registerButton: isLoginLandingPage,
      }}
    />
  );
};
